import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Emoji from 'components/helper/emoji'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import sketch from '../templates/page/prototyping.png'
import checkmark from '../templates/page/checkmark.svg'
import checkmark2 from '../templates/page/Path.svg'
import christoph from '../templates/page/christoph_bresler.jpg'
import christoph_square from '../templates/page/christoph_bresler_square.jpg'
import jenny from '../templates/page/jenny_ibelshaeuser.jpg'
import martin from '../templates/page/martin_knorr.jpg'

import babor from '../templates/page/logos/babor.svg'
import reifenhaeuser from '../templates/page/logos/reifenhaeuser.svg'
import opel_kohl from '../templates/page/logos/opel_kohl.png'

import prototype from '../assets/mockup-spacepilots-key-visual-prototyping-prototype.png'
import mockup from '../assets/mockup-spacepilots-key-visual-prototyping-mockup.png'
import { divide } from 'lodash'
import './index.scss'

class Index extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta
          site={siteMetadata}
          title="Digitale Innovation: schnell und günstig testen"
        />
        <Hero />
        <Video />
        <Why />
        <DigitalInnovation />
        <Ablauf />
        <ShowCase />
        <Testimonial />
        <Team />
        <CallToAction />
      </Layout>
    )
  }
}

const Hero = () => {
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    let el = document.getElementById('paper_prototype')
    let el2 = document.getElementById('paper_prototype2')
    let rect = el.getBoundingClientRect()
    if (window.scrollY <= rect.top) {
      el2.classList.remove('show')
    } else {
      el2.classList.add('show')
    }
  }

  return (
    <section className="bg-primary text-white d-flex align-items-center">
      <div className="container position-relative">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1 className="display-1">
              Professionelle App-Entwicklung <br></br>- Kosten sparen mit
              Prototyping
            </h1>
            <h2 className="display-3">
              Lernen Sie, wie Ihre Kunden/innen auf digitalen Produktideen
              reagieren, bevor Sie die teure Entwicklung starten.
            </h2>
          </div>
          <div className="col-12 col-lg-4 d-flex justify-content-center"></div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-8 col-lg-4">
            <a
              href="https://outlook.office365.com/owa/calendar/spacepilots1@spacepilots.co/bookings/s/PLp8DIRnKU-yGxWwo5gRKw2"
              className="btn btn-secondary call-to-action-font w-100 mb-2"
              style={{ whiteSpace: 'nowrap' }}
            >
              Kostenfreies Erstgespräch vereinbaren
            </a>
          </div>
          <div className="col-sm-4 col-lg-3">
            <a href="tel:+4922198652347" className="btn btn-light w-100">
              Jetzt direkt anrufen
            </a>
          </div>
        </div>
        <div className="prototype__shell">
          <div className="prototype__container">
            <img
              src={prototype}
              alt="Paper Prototype"
              className="img-fluid paper_prototype"
              id="paper_prototype"
            />
            <img
              src={mockup}
              alt="Paper Prototype"
              className="img-fluid paper_prototype paper_prototype2"
              id="paper_prototype2"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

class Collapsible extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }))

    return false
  }

  render() {
    return (
      <>
        <p className="lead mb-0">
          <span className="text-white btn-collapse" onClick={this.handleClick}>
            <strong>{this.props.title}</strong>{' '}
            {!this.state.isToggleOn ? (
              <FontAwesomeIcon icon={faCaretDown} className="down" />
            ) : (
              <FontAwesomeIcon icon={faCaretUp} className="up" />
            )}
          </span>
        </p>
        <p className={!this.state.isToggleOn ? 'd-none d-md-block' : ''}>
          {this.props.text}
        </p>
      </>
    )
  }
}

const Video = () => {
  return (
    <section className="d-flex align-items-center pb-0">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div className="embed-responsive embed-responsive-16by9 mb-4">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube-nocookie.com/embed/s7OAfb9Lxq0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-12 col-lg-8 offset-lg-2 mb-4">
            Christoph Bresler erklärt das Vorgehen und den Nutzen des
            App-Prototyping
          </div>
        </div>
      </div>
    </section>
  )
}

const Why = () => {
  return (
    <section id="why">
      <div className="container">
        <div className="row px-2">
          <div className="col-12 col-lg-8 mx-auto p-0">
            <h2 className="display-3 mb-4">
              <strong>Wozu Prototypen?</strong>
            </h2>
          </div>
        </div>
        <div className="row px-2">
          <div className="col-12 col-lg-8 mx-auto">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col p-0">
                    <img src={checkmark2} alt="" />
                  </div>
                  <div className="col-11 p-0">
                    <h3>Entscheidungsfindung</h3>
                    <p>
                      Gibt es einen angemessenen Mehrwert für Kunden? Gemeinsam
                      prüfen wir, ob sich die App-Entwicklung lohnt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col p-0">
                    <img src={checkmark2} alt="" />
                  </div>
                  <div className="col-11 p-0">
                    <h3>Vorstellen der Idee</h3>
                    <p>
                      Zum Vorstellen der Idee gegenüber Investoren, der
                      Geschäftsleitung oder anderen Entscheidungsträger/innen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col p-0">
                    <img src={checkmark2} alt="" />
                  </div>
                  <div className="col-11 p-0">
                    <h3>Festlegen des Funktionsumfang</h3>
                    <p>
                      Vor Start der Entwicklung wird der Funktionsumfang
                      definiert. Für ein Produkt, das Nutzer/innen überzeugt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col p-0">
                    <img src={checkmark2} alt="" />
                  </div>
                  <div className="col-11 p-0">
                    <h3>Übergabe an die Entwicklung</h3>
                    <p>
                      Ein Prototyp unterstützt das Entwickler-Team bei der
                      technischen Umsetzung in der App-Entwicklung.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const DigitalInnovation = () => {
  const [openMore, setOpenMore] = React.useState(false)
  return (
    <section id="how" className="bg-blue text-white d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-7 order-2 order-lg-1">
            <h2 className="display-2 mb-5" id="digitale-innovationen">
              <strong>Digitale Innovationen</strong> <br />
              endlich umsetzen!
            </h2>
            {!openMore ? (
              <div>
                <p className="lead">
                  <strong>Sie diskutieren im Unternehmen</strong> schon lange
                  über digitale Innovationen? Sie wünschen sich, Ihren
                  Kunden/innen einfachere und flexiblere Lösungen anzubieten?
                </p>
                <p className="lead">
                  <strong>Und jetzt?</strong> Sie wollen herausfinden, ob Ihre
                  App-Ideen funktionieren und ob Sie...
                </p>
                <a
                  className="btn btn-secondary"
                  onClick={() => setOpenMore(!openMore)}
                >
                  Weiterlesen
                </a>
              </div>
            ) : (
              <div>
                <p className="lead">
                  <strong>Sie diskutieren im Unternehmen</strong> schon lange
                  über digitale Innovationen? Sie wünschen sich, Ihren
                  Kunden/innen einfachere und flexiblere Lösungen anzubieten?
                </p>
                <p className="lead">
                  <strong>Und jetzt?</strong> Sie wollen herausfinden, ob Ihre
                  App-Ideen funktionieren und ob Sie von Ihren Nutzer/innen und
                  Kunden angenommen werden. Sie möchten frühzeitig wissen,
                  welche Erfolgschancen für die digitale Innovation bestehen.
                </p>
                <p className="lead">
                  <strong>Loslegen!</strong> Es ist Zeit, ein Testmodell oder
                  Prototypen des Produktes zu erstellen und zu testen. Denn Sie
                  möchten wissen, ob Ihre Idee bei den Nutzer/innen ankommt,
                  bevor Sie in die kostenintensive (Web-)App-Entwicklung
                  starten.
                </p>
                <p className="lead">
                  <strong>Wir übernehmen</strong> die Erstellung des Prototypen,
                  testen diesen mit Nutzer/innen, binden das Feedback ein und
                  bewerten, ob die Idee funktionieren kann oder nicht.
                </p>
                <p className="lead">
                  <strong>Im Anschluss</strong> helfen wir bei der Übergabe des
                  App-Konzepts an ein Entwicklerteam. Auf Wunsch stellen wir
                  Ihnen ein Entwicklerteam zusammen. Wir bleiben neutral bei der
                  Bewertung der Idee.
                </p>
                <p className="lead">
                  <strong>Frühes und günstiges Lernen</strong>, dass eine Idee
                  nicht funktioniert, ist dabei immer eine Option.
                </p>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-lg-5 order-1 order-lg-2 d-flex justify-content-center"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={sketch} alt="Prototyping" className="mt-5" />
          </div>
        </div>
      </div>
    </section>
  )
}

const Ablauf = () => {
  return (
    <section id="process" className="bg-medium-blue text-white">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="display-2 mb-5">
              So geht’s los: <strong>Der Ablauf</strong>
            </h2>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-3 col-md-4 text-center mb-4">
            <svg
              width="131"
              height="128"
              xmlns="http://www.w3.org/2000/svg"
              className="bullet_point img-fluid"
              viewBox="0 0 131 128"
            >
              <g fill="none">
                <path
                  d="M66.58 128C102.158 128 131 99.346 131 64c0-35.346-28.842-64-64.42-64S14.24 18.667 2.16 64c-12.078 45.333 28.842 64 64.42 64z"
                  fill="#1E345E"
                  opacity=".4"
                  className="bg"
                />
                <path
                  fill="#FFF"
                  className="number"
                  d="M70.65 82V47h-15.1v6.5h7V82z"
                />
              </g>
            </svg>
          </div>
          <div className="col-9 col-md-5">
            <Collapsible
              title="Kostenloses Erstgespräch"
              text="In einem Kennenlernen klären wir Ihre Ziele und machen einen
              Vorschlag zur Vorgehensweise. Wir beraten Sie und geben unsere
              erste Einschätzung zu Ihrer Idee."
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-3 col-md-4 text-center mb-4">
            <svg
              width="131"
              height="128"
              xmlns="http://www.w3.org/2000/svg"
              className="bullet_point img-fluid"
              viewBox="0 0 131 128"
            >
              <g fill="none">
                <path
                  d="M66.58 128C102.158 128 131 99.346 131 64c0-35.346-28.842-64-64.42-64S14.24 18.667 2.16 64c-12.078 45.333 28.842 64 64.42 64z"
                  fill="#1E345E"
                  opacity=".4"
                  className="bg"
                />
                <path
                  d="M79.35 82v-6.6H64.3l8.05-7.6c2.3-2.133 3.85-4.033 4.65-5.7.8-1.667 1.2-3.45 1.2-5.35 0-2.1-.542-3.925-1.625-5.475-1.083-1.55-2.6-2.75-4.55-3.6-1.95-.85-4.225-1.275-6.825-1.275-3.1 0-5.85.575-8.25 1.725-2.4 1.15-4.283 2.742-5.65 4.775l5.9 3.8c.833-1.167 1.867-2.042 3.1-2.625 1.233-.583 2.617-.875 4.15-.875 1.833 0 3.225.375 4.175 1.125.95.75 1.425 1.825 1.425 3.225 0 .967-.25 1.942-.75 2.925-.5.983-1.467 2.158-2.9 3.525L52.9 76.75V82h26.45z"
                  fill="#FFF"
                  className="number"
                />
              </g>
            </svg>
          </div>
          <div className="col-9 col-md-5">
            <Collapsible
              title="Kickoff-Workshop"
              text="In maximal 4 Stunden besprechen wir das Konzept, klären
              Verständnisfragen, verstehen die Kunden- und
              Geschäftsziele bestmöglich und skizzieren
              Lösungswege."
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-3 col-md-4 text-center mb-4">
            <svg
              width="131"
              height="128"
              xmlns="http://www.w3.org/2000/svg"
              className="bullet_point img-fluid"
              viewBox="0 0 131 128"
            >
              <g fill="none">
                <path
                  d="M66.58 128C102.158 128 131 99.346 131 64c0-35.346-28.842-64-64.42-64S14.24 18.667 2.16 64c-12.078 45.333 28.842 64 64.42 64z"
                  fill="#1E345E"
                  opacity=".4"
                  className="bg"
                />
                <path
                  d="M64.5 80.6c3.167 0 5.842-.517 8.025-1.55 2.183-1.033 3.808-2.408 4.875-4.125A10.455 10.455 0 0079 69.3c0-2.633-.817-4.842-2.45-6.625-1.633-1.783-3.983-2.925-7.05-3.425l7.9-9V45H52.9v6.5h14.9l-7.2 8.15V65h3.7c4.367 0 6.55 1.433 6.55 4.3 0 1.433-.583 2.542-1.75 3.325-1.167.783-2.767 1.175-4.8 1.175-1.833 0-3.625-.275-5.375-.825-1.75-.55-3.292-1.325-4.625-2.325l-3.15 6.2c1.667 1.2 3.692 2.125 6.075 2.775 2.383.65 4.808.975 7.275.975z"
                  fill="#FFF"
                  className="number"
                />
              </g>
            </svg>
          </div>
          <div className="col-9 col-md-5">
            <Collapsible
              title="Prototypen erstellen und testen"
              text="Wir setzen das Testmodell für Sie um und schließen uns mit
              Ihnen wöchentlich kurz. Das Projekt kann 2 bis 8 Wochen
              dauern, je nachdem wie komplex die App-Idee ist."
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-3 col-md-4 text-center mb-4">
            <svg
              width="131"
              height="128"
              xmlns="http://www.w3.org/2000/svg"
              className="bullet_point img-fluid"
              viewBox="0 0 131 128"
            >
              <g fill="none">
                <path
                  d="M66.58 128C102.158 128 131 99.346 131 64c0-35.346-28.842-64-64.42-64S14.24 18.667 2.16 64c-12.078 45.333 28.842 64 64.42 64z"
                  fill="#1E345E"
                  opacity=".4"
                  className="bg"
                />
                <path
                  fill="#FFF"
                  d="M73.075 82v-7.35h5.7v-6.6h-5.7V61.5h-7.65v6.55h-9.4L71.325 47h-8.5l-16.5 22.2v5.45h18.85V82z"
                  className="number"
                />
              </g>
            </svg>
          </div>
          <div className="col-9 col-md-5">
            <Collapsible
              title="Übergabe an Entwickler-Team"
              text="Nachdem die Prototypen erstellt, mit Nutzern getestet und
              verbessert wurden, entscheiden wir mit Ihnen, ob die
              Innovation eine Zukunft bekommt. Wenn ja, helfen wir bei der
              Übergabe an das zuständige App-Entwickler-Team. Auf
              Wunsch, sprechen wir eine Empfehlung für einen
              solches Team aus."
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const Team = () => {
  return (
    <section id="team" className="text-medium-blue mt-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="display-2 mb-5">
              <strong>
                Das spacepilots-Team für Prototyping in der App-Entwicklung
              </strong>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 col-lg-5">
            <p className="lead mb-0">
              <strong>Über 200 digitale Projekte</strong>
            </p>
            <p>
              Wir haben mit Mittelständler, großen aber auch mit jungen
              Unternehmen zusammen Mobiles Apps, Webanwendungen und Portale
              gestaltet, getestet und entwickelt.
            </p>
            <p className="lead mt-5 mb-0">
              <strong>Kreativ und technisch versiert</strong>
            </p>
            <p>
              Designer/innen und Entwickler/innen arbeiten zusammen. Es
              entstehen realisierbare Prototypen zur App-Entwicklung.
            </p>
            <p className="lead mt-5 mb-0">
              <strong>Digital Business im Blut</strong>
            </p>
            <p>
              Wir selbst arbeiten mit digitalen Geschäftsmodellen und kennen die
              Herausforderungen von digitalen Innovationen. We walk the talk.
            </p>
          </div>
          <div className="col-12 col-lg-6 offset-lg-1 mt-5 mt-lg-0">
            <div className="row">
              <div className="col-6 col-md-4">
                <img
                  src={christoph}
                  alt="Christoph Bresler"
                  className="img-fluid"
                />
                <p className="mt-2 mb-1">Christoph</p>
                <p className="small">Berater digitale Produkte</p>
              </div>
              {/*
              <div className="col-6 col-md-4">
                <img
                  src={jenny}
                  alt="Jennifer Ibelshäuser"
                  className="img-fluid"
                />
                <p className="mt-2 mb-1">Jennifer</p>
                <p className="small">UX-Designerin</p>
              </div>
               */}
              <div className="col-6 col-md-4">
                <img src={martin} alt="Martin Knorr" className="img-fluid" />
                <p className="mt-2 mb-1">Martin</p>
                <p className="small">UI-Designer & Frontend&shy;entwickler</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mt-4">
            Jetzt kostenfrei beraten lassen
          </div>
          <div className="col-12 text-center">
            <img src={require('../assets/arrow down.svg')} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

const ShowCase = () => {
  return (
    <div id="references">
      <section className="text-medium-blue pb-lg-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 order-lg-11">
              <img
                className="mb-lg-0 mb-4 max-w-100"
                src={require('../assets/CaseStudy-cheqpacs.gif')}
              ></img>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <h2 className="display-3">
                <strong>cheqpacs</strong>
              </h2>
              <div
                className="border-bottom border-primary mb-3 pb-3"
                style={{ border: '3px' }}
              >
                Design-Prototyp und App-Entwicklung
              </div>
              <div>
                cheqpacs klärt auf und schafft Transparenz in der
                Verpackungswelt. Mit dem integrierten Barcode-Scanner liefert
                die App binnen Sekunden die Bewertung einer Verpackung.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue text-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <video
                className="object-contain w-100 max-w-screen"
                src={require('../assets/CaseStudy-Skinbiotic.mp4')}
                autoPlay
                muted
                loop
              ></video>
            </div>
            <div className="col-12 col-lg-6 px-5">
              <h2 className="display-3">
                <strong>SKINBIOTIC</strong>
              </h2>
              <div
                className="border-bottom border-primary mb-3 pb-3"
                style={{ border: '3px' }}
              >
                Konzept, Prototyping, App-Entwicklung
              </div>
              <div>
                Eine native iPad App für die Behandlung durch Kosmetiker/innen
                für die neue Marke eines großen Herstellers von
                Kosmetikprodukten.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-medium-blue">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 order-lg-11">
              <video
                className="object-contain w-100 max-w-screen"
                src={require('../assets/Case-Studies-FactoringPlatform.mp4')}
                autoPlay
                muted
                loop
              ></video>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <div className="">
                <h2 className="display-3">
                  <strong>Factoring-Plattform</strong>
                </h2>
                <div
                  className="border-bottom border-primary mb-3 pb-3"
                  style={{ border: '3px' }}
                >
                  Prototyping. UX/UI Design.
                </div>
                <div>
                  Die Factoring-Plattform bietet KMUs die Übernahme von
                  Rechnungsversand und des Mahnwesens. Für die responsive
                  Webanwendung haben wir Designkonzepte und die Prototypen
                  entwickeln, um eine erfolgreiche Nutzererfahrung
                  sicherzustellen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const Testimonial = () => {
  return (
    <section className="bg-teal text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 text-center">
            <img
              className="testimonial-profile mb-4"
              src={require('../assets/team-sven.jpg')}
              alt="Sven"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="mb-4 d-none d-lg-block"
              src={require('../assets/quote.svg')}
              alt=""
            />
            <div className="mb-4">
              "Ich arbeite gerne mit den spacepilots. Sie denken mit, sind
              proaktiv im Projekt und bringen Kompetenzen ein, die sich in der
              Kombination nur schwer finden lassen."
            </div>
            <div className="text-center text-lg-left mb-2">
              <strong>Sven Pietsch</strong>, innoloft GmbH
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CallToAction = () => {
  return (
    <section className="bg-medium-blue">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <h2 className="text-center text-teal">
              Lassen Sie sich jetzt zu Ihrem digitalen Innovationsprojekt und
              der App-Entwicklung kostenlos beraten.
            </h2>
            <div className="text-center text-white">
              Mobile Apps | Web Apps | Progressive Web Apps | Webanwendungen
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-8 mx-auto">
            <div className="row">
              <div className="col-12 col-lg-8">
                <a
                  href="https://outlook.office365.com/owa/calendar/spacepilots1@spacepilots.co/bookings/s/PLp8DIRnKU-yGxWwo5gRKw2"
                  className="btn btn-secondary call-to-action-font w-100 mb-2"
                >
                  Kostenfreies Erstgespräch vereinbaren
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a href="tel:+4922198652347" className="btn btn-light w-100">
                  Jetzt direkt anrufen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Index
